:root,
  :root.light {
    --text: black;
    --background: white;
    --nav-background: white;
    --nav-link: #777;
    --nav-link-hover: black;
    --link: #337ab7;
    --banner: #f5f5f5;
    --code-background: #ededed;
  }

  :root.dark {
    --text: white;
    --background: #222;
    --nav-background: #222;
    --nav-link: #ddd;
    --nav-link-hover: white;
    --link: #9cd2ff;
    --banner: #333;
    --code-background: #676767;
  }

body {
  padding-top: 50px;

  color: var(--text);
  background: var(--background);

  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 18px;
  &.site-body {
    display: flex;
    min-height:  calc(100vh - 50px);
    flex-direction: column;
  }
}

a {
  color: var(--link);
  &:hover {
    color: var(--link);
  }
}

#mynav {
  background-color: var(--nav-background);
  border: 0px;
  .light-dark-switch {
    a {
      padding: 8px 15px;
      img {
        width: 34px;
        &.dark {
          // https://codepen.io/sosuke/pen/Pjoqqp
          filter: invert(49%) sepia(35%) saturate(0%) hue-rotate(221deg) brightness(87%) contrast(86%);
        }
        &.light {
          filter: invert(100%) sepia(0%) saturate(1734%) hue-rotate(64deg) brightness(116%) contrast(73%);
        }
      }
    }
  }
  .navbar-brand {
    background-color: #000000;
    color: #ffffff;
    .prompt {
      color: rgb(0, 255, 0)
    }

    @media only screen and (max-width: 767px) {
      margin-right: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    ul {
      float: left;
    }
    li {
      float: left;
      margin-right: 10px;
    }
    .navbar-nav {
        margin: 0px;
    }
  }
  .contact-link {
    padding-top: 16px;
    padding-bottom: 14px;
  }
  .navbar-nav {
    li {
      a {
        color: var(--nav-link);
        &:hover {
          color: var(--nav-link-hover);
        }
      }
    }
  }
}

#banner {
  background-color: var(--banner);

  #myname {
    padding: 20px 10px;
  }
}

#contact {
  margin-bottom: 30px;
}

#emailAddress {
  font-size: 22px;
}

#contactLinks {
  padding: 8px 0px;
}

.separator-dash {
  margin: 0px 6px;
}

.site-content {
  flex: 1;
}

h2 {
  font-size: 23px;
  &.misc-title {
    margin-bottom: 0px
  }
}

h3 {
  font-size: 20px;
  margin: 13px 0px;
}

blockquote {
  background: #f9f9f9;
  border-left: 6px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  font-style: italic;
}

section {
  margin-top: 60px;

  @media only screen and (max-width: 767px) {
    margin-top: 30px;
  }
}

.description {
  padding: 10px 0px;
}

.note {
  padding-top: 30px;
}

.introduction {
  .mini-bio {
    p {
      margin: 15px 0px;

      @media only screen and (max-width: 767px) {
        padding-bottom: 22px
      }
    }
  }
}

.skills {
  text-align: center;
  .skill-title {
    h3 {
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
    }
  }
}

#cvTitle {
  h1 {
    font-size: 26px;
    text-align: center;
    margin-bottom: 15px;
  }
  #cvHeader {
    ul {
      margin: 30px 0px 0px 0px;
      padding: 0;
      li {
        list-style: none;
        margin: 10px 0px;
      }
    }
  }
}

.cv-section {
  margin: 25px 15px 0px 15px;
  h2 {
    font-size: 23px;
    &.misc {
      margin-bottom: 25px;
    }
  }
  .experience {
    padding: 15px 0px 20px 0px;

    ul {
      margin: 0px;
      @media only screen and (max-width: 767px) {
        padding-left: 15px;
      }
    }
    .environment {
      margin-top: 15px;
    }
    .gitlab-section {
      margin-bottom: 15px;
    }

    .logo-role {
      margin: 25px 0px;

      &.no-logo {
        margin: 15px 0px 10px 0px;
      }

      .logo {
        #celadLink {
          img {
            width: 120px;
            margin-right: 20px;
          }
        }

        #madlobaLink {
          img {
            width: 80px;
          }
        }

        &.company-link {
          a {
            img {
              width: 150px;
            }
          }

          @media only screen and (max-width: 767px) {
            margin-bottom: 15px;
          }
        }

        @media only screen and (min-width: 768px) {
          float: left;
        }

        @media only screen and (max-width: 767px) {
          padding-bottom: 0px;
          padding-top: 25px;
          text-align: center;
        }
      }
      .cv-role {
        line-height: 10px;

        .no-logo {
          ul {
            padding-left: 15px;
          }
        }

        .role-title {
          font-size: 18px;
        }

        .cv-time-period {
          font-size: 14px;
        }

        &.rd {
          line-height: 0px;
          @media only screen and (max-width: 768px) {
            line-height: 20px;
          }
        }

        &.celad {
          line-height: 20px;
        }

        &.madloba {
          line-height: 25px;
        }
        @media only screen and (min-width: 768px) {
          &.tig {
            padding-top: 17px;
          }
          &.rd {
            padding-top: 16px;
          }
          &.madloba {
            padding-top: 38px;
          }
          &.aj {
            padding-top: 28px;
          }
          &.financeit {
            padding-top: 14px;
          }
          &.gitlab {
            padding-top: 28px;
          }
        }

        @media only screen and (max-width: 767px) {
          padding-bottom: 0px;
          padding-top: 0px;
          text-align: center;
          line-height: 20px;
        }
      }
    }

    .role-desc {
      text-decoration: underline;
    }

    .role-period {
      font-weight: bold;
    }

    .girls-that-code {
      margin: 10px 0px 20px 0px;
    }
  }

  #techInterests {
    ul {
      margin-left: 0px;
      @media only screen and (max-width: 767px) {
        padding-left: 15px;
      }
    }
  }

  .other {
    margin: 10px 0px;
  }

  #backToHome {
    margin-bottom: 60px;
  }
}

#personalInterests {
  margin-bottom: 50px;
}

.item-list {
  span {
    font-size: 14px;
    margin-left: 6px;
  }

  li {
    margin-bottom: 8px;
  }

  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  ul {
    margin-left: 0px;
    padding-left: 15px;

    li {
      padding: 5px 0px;
    }
  }

  .navbar-nav {
    li {
      padding: 0px;
    }
  }
}

.page {
  .rss-link {
    font-size: 14px;
    font-weight: normal;
    margin-left: 10px;
  }

  p {
    margin: 20px 0px 35px 0px;
  }

  pre.highlight {
    margin: 25px 0px 40px 0px;
    padding: 20px;
    background: var(--code-background);
  }

  code {
    background: var(--code-background);
    padding: 0px 4px;
  }

  ul {
    margin: 35px 0px 35px 0px;
    li {
      p {
        margin-top: 3px;
      }
    }
  }

  em {
    font-style: italic;
    margin-bottom: 35px;
    font-size: 16px;
  }

  #backToMusic {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 22px;
    margin: 55px 0px 25px 0px;
  }
}
